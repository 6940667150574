import { Flex } from "@chakra-ui/react";
import { Styled, TablelStyled } from "./styled";
import DotSymboi from "component/DotSymboi";
import { forwardRef, Fragment, useEffect, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  DATE_ERA_FORMAT,
  DATE_ERA_FORMAT_2,
  DATE_ERA_FORMAT_YEAR,
  DATE_ERA_FORMAT_YEAR_MONTH,
  DATE_FORMAT_2,
  MONTH_FORMAT,
  JAPANESE_ERA_YEARS,
  YEAR_FORMAT,
  MONTH_FORMAT_2,
} from "constant/date";
import { IItemService, IService, IShift } from "types/admin";
import moment from "moment";
import { IServiceType } from "constant/interfaces";
import { toNumber } from "lodash";
import japaneseHolidays from "japanese-holidays";

const NUMBER_ITEMS_OF_YEARS = 4; // number items of years birthday era
interface IProps {
  createdDate: Dayjs;
  dataPrint: IItemService[] | null;
  setIsReadyPrint: React.Dispatch<React.SetStateAction<boolean>>;
  currentDate?: Dayjs;
  serviceTypes: IServiceType[];
  services: IService[];
}
const ServiceDeliveryTicket = (props: IProps, ref: any) => {
  const {
    dataPrint = [],
    createdDate,
    setIsReadyPrint,
    currentDate,
    serviceTypes,
    services,
  } = props;
  const yearMonth = dayjs(currentDate).format(MONTH_FORMAT);
  const year = dayjs(currentDate).format(YEAR_FORMAT);
  const month = dayjs(currentDate).format(MONTH_FORMAT_2)
  const daysInMonth = dayjs(yearMonth).daysInMonth();
  const monthSelected = moment(dayjs(currentDate).format(DATE_FORMAT_2)).format(
    DATE_ERA_FORMAT_YEAR_MONTH
  );

  const redDaysInYear = useMemo(() => {
    const result: { [key: string]: number[] } = {};

    japaneseHolidays.getHolidaysOf(toNumber(year)).forEach((redDay) => {
      const { month, date } = redDay;
      if (!result[month]) result[month] = [];
      result[month].push(date);
    });

    return result;
  }, [year]);


  const daysArray = useMemo(() => {
    let result = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = dayjs(`${yearMonth}/${day}`);
      const redDaysOfMonth = redDaysInYear[toNumber(month)] || []
      const dayOfWeek = date.locale("ja").format("dd")
      const isSunday = dayOfWeek === "日" // 日 = sunday
      result.push({
        dayOfMonth: day,
        dayOfWeek,
        date: date.format(DATE_FORMAT_2),
        isHoliday: redDaysOfMonth.includes(day) || isSunday,
      });
    }
    return result;
  }, [yearMonth]);

  const getJsxSeparate = (maxLen: number, text?: string) => {
    const textElements: JSX.Element[] = [];
    for (let i = 0; i < maxLen; i++) {
      if (text && text[i]) {
        textElements.push(<div className="justify-center">{text[i]}</div>);
      } else {
        textElements.push(<div></div>);
      }
    }
    return textElements;
  };

  const handleMapingScheduleShift = (shifts: IItemService["shifts"]) => {
    const result: {
      [timeRangeAsKey: string]: {
        [serviceTypeAsKey: string]: {
          [dateAskey: string]: IShift["schedules"][0];
        };
      };
    } = {};
    shifts.forEach((shift) => {
      shift.schedules.forEach((schedule) => {
        const timeStartFormat = schedule?.start_time
          ? dayjs(schedule.start_time?.split(".")[0]).format("ss:mm")
          : "";
        const timeEndFormat = schedule?.start_time
          ? dayjs(schedule.end_time?.split(".")[0]).format("ss:mm")
          : "";
        const serviceTypeId = schedule.nursing_care_history.service_type_id;
        const serviceId = schedule.nursing_care_history.service_id;

        const key = `${serviceTypeId}-${serviceId}`;
        const timeRange = `${timeStartFormat}~${timeEndFormat}`;
        if (!result[timeRange]) result[timeRange] = { [key]: {} };
        if (!result[timeRange][key]) result[timeRange][key] = {};
        result[timeRange][key][shift.date] = schedule;
      });
    });
    return result;
  };

  const getRowPlanOrActual = (
    shiftsObj: ReturnType<typeof handleMapingScheduleShift>,
    keyAsTimeRange: string | number,
    keyAsServiceType: string | number
  ) => {
    const jsxPlan: JSX.Element[] = [];
    const jsxActual: JSX.Element[] = [];
    let totalPlan: number = 0;
    let totalActual: number = 0;
    const firstHistoryItem = Object.values(
      shiftsObj[keyAsTimeRange][keyAsServiceType]
    )[0]?.nursing_care_history;
    const service =
      services?.find((x) => x.id == firstHistoryItem?.service_id)
        ?.service_name || "";

    const serviceType =
      serviceTypes.find((x) => x.id == firstHistoryItem?.service_type_id)
        ?.detail || "";

    daysArray.forEach((item) => {
      const scheduleItem =
        shiftsObj[keyAsTimeRange][keyAsServiceType][item.date];
      totalPlan += scheduleItem?.plan || 0;
      totalActual += scheduleItem?.actual || 0;

      jsxPlan.push(
        <td
          key={item.dayOfMonth}
          className="min-w-[2.5mm] max-w-[2.5mm] min-h-[2.5mm] max-h-[2.5mm] text-center !border-[0.5mm]"
        >
          {scheduleItem?.plan}
        </td>
      );

      jsxActual.push(
        <td
          key={item.dayOfMonth}
          className="min-w-[2.5mm] max-w-[2.5mm] min-h-[2.5mm] max-h-[2.5mm] text-center"
        >
          {scheduleItem?.actual}
        </td>
      );
    });

    return { jsxPlan, jsxActual, totalPlan, totalActual, serviceType, service };
  };

  useEffect(() => {
    setIsReadyPrint(true);
  }, []);

  const createdDateEra = moment(
    dayjs(createdDate).format(DATE_FORMAT_2)
  ).format(DATE_ERA_FORMAT.replaceAll(" ", ""));

  const getListYearEra = (yearBirthDayEra: string) => {
    const indexFind = JAPANESE_ERA_YEARS.findIndex(
      (x) => x === yearBirthDayEra
    );
    if (indexFind === -1) return [];
    if (indexFind === 0) {
      return JAPANESE_ERA_YEARS.slice(0, NUMBER_ITEMS_OF_YEARS);
    }

    if (indexFind + NUMBER_ITEMS_OF_YEARS < JAPANESE_ERA_YEARS.length - 1) {
      return JAPANESE_ERA_YEARS.slice(
        indexFind,
        indexFind + NUMBER_ITEMS_OF_YEARS
      );
    }

    return JAPANESE_ERA_YEARS.slice(
      JAPANESE_ERA_YEARS.length - 1 - NUMBER_ITEMS_OF_YEARS,
      JAPANESE_ERA_YEARS.length - 1
    );
  };

  return (
    <Styled ref={ref} hidden>
      {dataPrint?.map((item, index) => {
        const { client, insurance_card, shifts, profile } = item;
        const birthDate = moment(
          dayjs(client.birth_date).format(DATE_FORMAT_2)
        ).format(DATE_ERA_FORMAT_2);

        const startInsurance = insurance_card?.start_insurance
          ? moment(
              dayjs(insurance_card?.start_insurance).format(DATE_FORMAT_2)
            ).format(DATE_ERA_FORMAT)
          : "";

        const endInsurance = insurance_card?.end_insurance
          ? moment(
              dayjs(insurance_card?.end_insurance).format(DATE_FORMAT_2)
            ).format(DATE_ERA_FORMAT)
          : "";
        const shiftAsObj = handleMapingScheduleShift(shifts);
        const yearBirthDayEra = moment(
          dayjs(client.birth_date).format(DATE_FORMAT_2)
        ).format(DATE_ERA_FORMAT_YEAR);
        const listYearEras = getListYearEra(yearBirthDayEra);

        const header = (
          <Flex
            alignItems={"end"}
            height={"14mm"}
            justifyContent={"space-between"}
            className="mb-[1mm]"
          >
            <Flex>
              <div className="inline-flex  justify-between items-center border w-[31mm] h-[8mm] text-[3mm] px-[1mm] mb-[0.8mm] ">
                <div className="border  rounded-[100%] px-[2mm] py-[0.8mm]">
                  認定済
                </div>
                <DotSymboi size="0.5mm" />
                <div>申請中</div>
              </div>
              <div className="h-full ml-[5mm]">{monthSelected} 分</div>
              <div className="font-bold text-[4.5mm] h-full ml-[50mm]">
                サービス提供票
              </div>
            </Flex>
            <div className="border inline-flex items-center text-[3mm] h-[5mm] px-[3mm]">
              サービス事業所→居宅介護支援事業所
            </div>
          </Flex>
        );

        const tableInfo = (
          <div className="border border border-t-2 border-l-2 text-[2.8mm]">
            <div className="row-table grid grid-cols-[20fr_20fr_5fr_5fr_5fr_5fr_5fr_5fr_32fr_40fr_24fr_63fr_15fr_36fr]">
              <div>
                保険者 <br />
                番号
              </div>
              <div> </div>
              {getJsxSeparate(6, insurance_card?.insurance_company_number)}
              <div className=""> 保険者名</div>
              <div className=""> {insurance_card?.insurance_name}</div>
              <div className="">舌宅介護支援 <br/> 事業者事業所名 <br/> 担当者名(TEL)</div>
              <div className="flex-col !justify-between !items-start">
                <p>介護相談センターみ·ず·ほ </p> <p>菊池 慶(052-688-7480)</p>
              </div>
              <div className="">
                作成
                <br />
                年月日
              </div>
              <div className="items-center justify-end">{createdDateEra}</div>
            </div>
            <div className="row-table grid grid-cols-[20fr_5fr_5fr_5fr_5fr_5fr_5fr_5fr_5fr_5fr_5fr_32fr_59fr_15fr_53fr_15fr_36fr]">
              <div className="">
                被保険者
                <br />
                番号
              </div>
              {getJsxSeparate(10, insurance_card?.insurance_number)}
              <div className=" ">
                フリガナ <br />
                被保険者氏名
              </div>
              <div className="!p-0 !block">
                <div className="border-b-[0.2mm]  p-[0.5mm]">
                  {client.name_kana}
                </div>
                <div className="flex items-center justify-between py-[1mm] p-[0.5mm]">
                  <p>
                    {client.last_name} {client.first_name}
                  </p>
                  <p>様</p>
                </div>
              </div>
              <div className="items-center ">
                保険者
                <br />
                確認印
              </div>
              <div></div>
              <div className="items-center ">
                届出
                <br />
                年月日
              </div>
              <div></div>
            </div>
            <div className="row-table grid grid-cols-[20fr_25fr_10fr_15fr_32fr_40fr_19fr_35fr_18fr_30fr_18fr_18fr]">
              <div className=" ">生年月日</div>
              <div className="flex-col justify-between !p-[2mm]">
                <div className="flex items-center">
                  {listYearEras.reverse().map((item, index) => (
                    <Fragment key={item}>
                      <div
                        className={
                          item === yearBirthDayEra
                            ? `border-[0.7mm] p-[0.4mm] rounded-[100%] flex items-center justify-center h-[5mm] w-[5mm]`
                            : ""
                        }
                      >
                        {item.split("")[0]}
                      </div>
                      {index !== listYearEras.length - 1 && (
                        <DotSymboi size="0.8mm" className="mx-[0.8mm]" />
                      )}
                    </Fragment>
                  ))}
                </div>
                <div>{birthDate}</div>
              </div>
              <div>性別</div>
              <div className=" justify-evenly">
                <div
                  className={
                    client.sex === "男"
                      ? "flex justify-center items-center border-[0.7mm] rounded-[100%]  w-[6mm]"
                      : ""
                  }
                >
                  男
                </div>
                <DotSymboi size="0.7mm" />
                <div
                  className={
                    client.sex === "女"
                      ? "flex justify-center items-center border-[0.7mm] rounded-[100%]  w-[6mm]"
                      : ""
                  }
                >
                  女
                </div>
              </div>
              <div className="!p-0 !block ">
                <div className="border-b-[0.3mm] p-[0.4mm] min-h-[4.5mm]">
                  要介護状態区分
                </div>
              </div>
              <div className="!p-0 !block ">
                <div className="flex justify-center border-b-[0.3mm] p-[0.4mm] min-h-[4.5mm] ">
                  {insurance_card?.care_level}
                </div>
                <div></div>
                <div></div>
              </div>
              <div>区分支給<br/>限度基準額</div>
              <div className=" flex-col justify-between !py-[2mm]">
                <div className="font-bold text-[7mm]">30938</div>{" "}
                <div className="text-right w-full">単位/月</div>
              </div>
              <div>
                限度額
                <br />
                適用期間
              </div>
              <div className="flex-col ">
                <p>{startInsurance}</p>
                <p>から</p>
                <p>{endInsurance}</p>
                <p>まで</p>
              </div>
              <div>前月まで<br/>の短期入所<br/>利用日数</div>
              <div className="!justify-end">
                <span className="font-bold  text-[6mm]">0</span>日
              </div>
            </div>
          </div>
        );

        const isLastItem = dataPrint.length - 1 === index;
        return (
          <div
            className={`w-[297mm] text-[4mm] leading-3 ${
              isLastItem ? "" : "break-after-page"
            }`}
            key={client.id}
          >
            {header}
            {tableInfo}
            <TablelStyled className="mt-[4mm] text-[2.8mm]">
              <Fragment>
                <tr>
                  <td rowSpan={3}>提供時間帯</td>
                  <td rowSpan={3}>サービス内容</td>
                  <td rowSpan={3} className="!border-r-0">
                    サービス <br />
                    事業者 <br />
                    事業所名
                  </td>
                  <td className="!border-l-0 !border-x-[1mm] text-center"></td>
                  <td colSpan={daysArray.length + 1} className="text-center ">
                    月間サービス計画及び実績の記録
                  </td>
                </tr>
                <tr>
                  <td className="!border-[0.5mm] !border-x-[1mm] text-center">
                    日付
                  </td>
                  {daysArray.map((item, index) => (
                    <td
                      key={item.dayOfMonth}
                      className="min-w-[1mm] max-w-[1mm] !border-[0.5mm] text-center"
                    >
                      <div className="justify-center ">{item.dayOfMonth}</div>
                    </td>
                  ))}
                  <td rowSpan={2}>
                    <div className="flex justify-center items-center">
                      合計 <br /> 回数
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="!border-x-[1mm] text-center">曜日</td>
                  {daysArray.map((item) => (
                    <td
                      key={item.dayOfMonth}
                      className="min-w-[2.5mm] max-w-[2.5mm] min-h-[2.5mm] max-h-[2.5mm] !py-0 "
                    >
                      <div
                        className={`${
                          item.isHoliday
                            ? "border rounded-[100%] border-[0.5mm]"
                            : ""
                        } h-full w-full flex justify-center items-center`}
                      >
                        {item.dayOfWeek}
                      </div>
                    </td>
                  ))}
                </tr>
              </Fragment>

              {Object.keys(shiftAsObj)
                .sort()
                .map((key: keyof typeof shiftAsObj) => {
                  return Object.keys(shiftAsObj[key]).map(
                    (keyAsServices, index) => {
                      const {
                        jsxPlan,
                        jsxActual,
                        totalPlan,
                        totalActual,
                        serviceType,
                        service,
                      } = getRowPlanOrActual(shiftAsObj, key, keyAsServices);
                      return (
                        <Fragment key={index + "-" + keyAsServices}>
                          <tr>
                            <td rowSpan={2}>{key}</td>
                            <td rowSpan={2}>{serviceType}</td>
                            <td rowSpan={2}>{profile.facility_name}</td>
                            <td className="!border-[0.5mm] !border-x-[1mm] text-center">
                              予定
                            </td>
                            {jsxPlan}
                            <td className="text-end !border-[0.5mm]">
                              <span className="!pr-[0.9mm]">{totalPlan}</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="!border-x-[1mm] text-center">
                              実績
                            </td>
                            {jsxActual}
                            <td className="text-end ">
                              <span className="!pr-[0.9mm]">{totalActual}</span>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    }
                  );
                })}
            </TablelStyled>
          </div>
        );
      })}
    </Styled>
  );
};

export default forwardRef(ServiceDeliveryTicket);
