import { useEffect, useState } from "react";
import { Button, DatePicker, Form, ModalProps } from "antd";
import ModalStyled from "./styled";
import { Flex } from "@chakra-ui/react";
import { ButtonSolid } from "component/button";
import usePrint from "hook/usePrint";
import { useForm } from "antd/es/form/Form";

import ServiceDeliveryTicket from "../PDFs/ServiceDeliveryTicket";
import apiFactory from "api";
import { Dayjs } from "dayjs";
import { MONTH_FORMAT } from "constant/date";
import { IItemService, IService } from "types/admin";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import { getCookie } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IServiceType } from "constant/interfaces";
import React from "react";
import { CloseOutlined } from "@ant-design/icons";

export type IModalPrintServicePDFProps = {
  currentDate?: Dayjs;
  clientIdsObj?: { [key: string]: { checked: boolean; clientId: number } };
  setModalPDF: React.Dispatch<
    React.SetStateAction<{
      type: "service" | "notificationOfAmountReceived";
      open: boolean;
    } | null>
  >;
} & ModalProps;

const ModalPrintServicePDF = ({
  currentDate,
  clientIdsObj,
  open,
  setModalPDF,
  ...restProps
}: IModalPrintServicePDFProps) => {
  const [form] = useForm();

  const [dataPrint, setDataPrint] = useState<IItemService[] | null>(null);
  const [isReadyPrint, setIsReadyPrint] = useState(false);
  const [printing, setPrinting] = useState(false);
  const { ref, handlePrint } = usePrint(true, {
    onAfterPrint: () => {
      setPrinting(false);
    },
  });
  const user = useSelector((state: RootState) => state.user);
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
  const [services, setServices] = useState<IService[]>([]);

  const createdDate = Form.useWatch("createdDate", form);
  const handleClose = () => {
    setModalPDF({
      type: "service",
      open: false,
    });
    form.resetFields();
  };
  const footer = (
    <Flex gap={4} className="mt-[46px]">
      <Button className="flex-1 h-12 !rounded-sm" onClick={handleClose}>
        キャンセル
      </Button>
      <ButtonSolid
        className="flex-1 !h-12 !rounded-sm border"
        onClick={form.submit}
        isLoading={printing}
        isDisabled={!createdDate}
      >
        確認
      </ButtonSolid>
    </Flex>
  );
  const idToken = getCookie("access_token");

  const handleSubmit = async () => {
    try {
      const year_month = currentDate?.format(MONTH_FORMAT);
      if (!clientIdsObj) return;
      setPrinting(true);
      const checkIdExisted: any = {};
      const client_ids = Object.values(clientIdsObj).reduce((acc, cur) => {
        if (checkIdExisted[cur.clientId]) return acc;
        acc.push(cur.clientId);
        checkIdExisted[cur.clientId] = true;
        return acc;
      }, [] as number[]);
      const param = {
        per: "10000",
      };

      const promiseDataPrint =
        apiFactory.adminNursingCareRecordApi.pdfServiceDelivery({
          year_month,
          client_ids,
        });
      const promiseServiceTypes = nursingCareRecordApi.getServiceType(
        idToken,
        param,
        user?.role
      );

      const promiseServices = nursingCareRecordApi.getPatientService(
        idToken,
        {
          get_all: true,
        },
        user?.role
      );
      Promise.all([promiseDataPrint, promiseServiceTypes, promiseServices])
        .then((result) => {
          const [dataPrint, dataServiceTypes, dataServices] = result;
          const data = dataPrint.data as IItemService[];
          const serviceTypes =
            dataServiceTypes.serialized_service_types as IServiceType[];
          const services = dataServices.data as IService[];

          setServiceTypes(serviceTypes);
          setDataPrint(data);
          setServices(services);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      setPrinting(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    if (isReadyPrint) {
      handlePrint();
      setDataPrint(null);
      setIsReadyPrint(false);
    }
  }, [isReadyPrint]);

  return (
    <ModalStyled
      open={open}
      footer={footer}
      {...restProps}
      onCancel={() => {}}
      closeIcon={
        <div onClick={handleClose}>
          <CloseOutlined />
        </div>
      }
    >
      <h1 className="font-bold text-xl mb-5">ダウンロード前の確認</h1>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          key="3 "
          className="w-full"
          rules={[{ required: true }]}
          name="createdDate"
          label="作成日"
        >
          <DatePicker className="h-10  w-full" placeholder="作成日" />
        </Form.Item>
      </Form>
      {dataPrint && dataPrint.length > 0 && (
        <ServiceDeliveryTicket
          ref={ref}
          dataPrint={dataPrint}
          createdDate={form.getFieldValue("createdDate")}
          setIsReadyPrint={setIsReadyPrint}
          currentDate={currentDate}
          serviceTypes={serviceTypes}
          services={services}
        />
      )}
    </ModalStyled>
  );
};

export default ModalPrintServicePDF;
