import { context } from "./TableContext";
import { useContext } from "react";
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row: React.FC<RowProps> = ({ ...props }) => {
  const { listSchedulesDeleted } = useContext(context);
  const isDeleted = listSchedulesDeleted?.includes(props["data-row-key"]);
  return isDeleted ? <></> : <tr {...props} />;
};

export default Row;
