import dayjs, { Dayjs } from "dayjs";
import {
  createSearchParams,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  DATE_FORMAT,
  DAYS_IN_WEEK,
  MONTH_FORMAT,
  TIME_FORMAT,
  formatDate,
} from "constant/date";
import { Checkbox, Modal, Popover, Table, TableProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ButtonSolid } from "component/button";
import MonthChanger, { CURRENT_DATE_KEY } from "component/MonthChanger";
import ROUTES from "constant/routes";
import { useQuery } from "react-query";
import apiFactory from "api";
import { IItemService, NursingCareHistoryI } from "types/admin";
import {
  MappingComplextion,
  MappingDevision,
  MappingSweating,
} from "enums/nursingCare";
import { MAX_QUERY_PER_PAGE } from "constant/apiRequest";
import { getCookie } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ModalReceiptAmountPrintPDF from "./components/ModalReceiptAmountPrintPDF";
import ModalPrintServicePDF from "./components/ModalPrintServicePDF";
import ServiceProvisionRecord from "./components/PDFs/ServiceProvisionRecord";

import { CheckboxChangeEvent } from "antd/es/checkbox";
import { isEmpty } from "lodash";
import { IServiceType } from "constant/interfaces";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import usePrint from "hook/usePrint";
import PrintPdfNursingCareRecord from "../NursingCareRecord/components/PrintPdfNursingCareRecord";

export const START_OF_DATE = "00:00";
export const END_OF_DATE = "24:00";

function NursingCareRecordDetail() {
  const [searchParams] = useSearchParams();
  const { id } = useParams(); //equal nurseId
  const [currentDate, setCurrentDate] = useState<Dayjs>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();

  const [printing, setPrinting] = useState(false); // set state printing  for ServiceProvisionRecord btn 居宅介護サービス提供実績記録票
  const [dataPrint, setDataPrint] = useState<IItemService[]>([]); // set data print for ServiceProvisionRecord btn 居宅介護サービス提供実績記録票
  const [isReadyPrint, setIsReadyPrint] = useState(false); // set data print for ServiceProvisionRecord btn 居宅介護サービス提供実績記録票
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]); // set data print for ServiceProvisionRecord btn 居宅介護サービス提供実績記録票
  const [preventAction, setPreventAction] = useState(false);

  const [isOpenBtnsPdf, setIsOpenBtnsPdf] = useState(false);


  // State for histories patient pdf
  const [isHistoryPrinting, setIsHistoryPrinting] = useState(false);
  const [paramsHistory, setParamsHistory] = useState<{
    patientId: number | "";
    nurseId: number | "";
    id: number | "";
    isGetAll?: boolean;
  } | null>(null);

  const [modePrint, setModePrint] = useState<"portrait" | "landscape">(
    "portrait"
  );

  const { ref, handlePrint } = usePrint(true, {
    onBeforeGetContent: () => {
      setPrinting(false);
    },
    onAfterPrint: () => {
      setPreventAction(false);
    },
  });

  const [nursingCareHitories, setNursingCareHitories] =
    useState<NursingCareHistoryI[]>();
  const [clientIdsObj, setClientIdsObj] = useState<{
    [key: string]: { checked: boolean; clientId: number };
  }>({});

  const [modalPDF, setModalPDF] = useState<{
    type: "service" | "notificationOfAmountReceived";
    open: boolean;
  } | null>(null);

  const [isFetchingNursingCareHistories, setIsFetchingNursingCareHistories] =
    useState<boolean>(false);

  const { isLoading } = useQuery(
    ["nursing-care-detail", currentDate, id],
    () =>
      apiFactory.adminNursingCareRecordApi.getNursingCareDetail({
        year_month: currentDate?.format(MONTH_FORMAT),
        nurse_id: id,
        per: MAX_QUERY_PER_PAGE,
      }),
    {
      enabled: !!currentDate,
      onSuccess: async (data) => {
        try {
          const check: any = {};
          setIsFetchingNursingCareHistories(true);
          const newNursingCareHistories =  data?.serialized_nursing_care_histories || []
          setNursingCareHitories(newNursingCareHistories);
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetchingNursingCareHistories(false);
        }
      },
    }
  );

  const handleOnChangeSelectAll = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    if (checked) {
      const result: typeof clientIdsObj = {};
      nursingCareHitories?.forEach((item) => {
        result[item.id] = { checked: true, clientId: item.patient.id };
      });
      setClientIdsObj(result);
    } else {
      setClientIdsObj({});
    }
  };

  const columns: TableProps<any>["columns"] = useMemo(() => {
    return [
      {
        title: (
          <Checkbox
            className="ignone_popup_pdf"
            onChange={handleOnChangeSelectAll}
            checked={
              !!nursingCareHitories?.length &&
              nursingCareHitories.length === Object.keys(clientIdsObj).length
            }
          />
        ),
        key: "checked",
        align: "center" as const,
        width: 10,
        render: (_: string, record: NursingCareHistoryI, index: number) => {
          return (
            <div className="whitespace-nowrap flex gap-x-1 items-center ignone_popup_pdf">
              <Checkbox
                className="mb-1"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const cloneClientIdsObj = { ...clientIdsObj };
                  if (checked) {
                    cloneClientIdsObj[record.id] = {
                      checked,
                      clientId: record.patient.id,
                    };
                  } else {
                    delete cloneClientIdsObj[record.id];
                  }

                  setClientIdsObj({ ...cloneClientIdsObj });
                }}
                checked={clientIdsObj[record.id]?.checked}
              />
            </div>
          );
        },
      },
      {
        title: "お客様",
        dataIndex: "id",
        key: "id",
        align: "center" as const,
        width: 200,
        render: (_: string, record: NursingCareHistoryI, index: number) => {
          const dayInWeek =
            DAYS_IN_WEEK?.[dayjs(searchParams.get("updated_time")).day()];

          return (
            <p className="whitespace-nowrap flex gap-x-[20px]">
              <span className="min-w-[20px]">{index + 1}</span>
              <span>{dayInWeek}</span>
              <span className="max-w-[200px] truncate">
                {record?.patient?.family_name}
              </span>
            </p>
          );
        },
      },
      {
        title: "開始時刻",
        dataIndex: "start_time",
        key: "start_time",
      },
      {
        title: "終了時刻",
        dataIndex: "end_time",
        key: "end_time",
        render: (text: string, record: NursingCareHistoryI) => {
          if (text === START_OF_DATE) {
            return END_OF_DATE;
          }

          return text;
        },
      },
      {
        title: "対応終了実時間",
        dataIndex: "updated_time",
        key: "updated_time",
        render: (_: string, record: NursingCareHistoryI) => {
          return dayjs(record?.updated_time).format(TIME_FORMAT);
        },
      },
      {
        title: "区分",
        dataIndex: "division",
        key: "division",
        render: (text: string) => {
          return MappingDevision[text as keyof typeof MappingDevision];
        },
      },
      {
        title: "顔色",
        dataIndex: "complexion",
        key: "complexion",
        render: (text: string) =>
          MappingComplextion[text as keyof typeof MappingComplextion],
      },
      {
        title: "発汗",
        dataIndex: "sweating",
        key: "sweating",
        render: (text: string) =>
          MappingSweating[text as keyof typeof MappingSweating],
      },
      {
        title: "排尿",
        dataIndex: "urination",
        key: "urination",
      },
      {
        title: "排便",
        dataIndex: "defecation",
        key: "defecation",
      },
      {
        title: "水分",
        dataIndex: "hydration",
        key: "hydration",
      },
      {
        title: "全身浴",
        dataIndex: "full_body_bath",
        key: "full_body_bath",
      },
      {
        title: "",
        dataIndex: "button_action",
        key: "button_action",
        render: (_, item) => (
          <div>
            <ButtonSolid
              h="26px"
              fontSize="14px"
              w="46px"
              onClick={() => {
                const itemAny = item as any;
                navigate({
                  pathname: `/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${ROUTES.SHIFT_MANAGEMENT}/${item.patient.id}/`,
                  search: createSearchParams({
                    date: item.schedule_date.date,
                    seriveName: item.service.service_name,
                    isHistory: "true",
                    redirectPath: location.pathname + location.search,
                    schedule_date_id: item.schedule_date_id,
                    division: itemAny.division,
                    patient: item.patient.last_name + item.patient.first_name,
                    type: "edit",
                  }).toString(),
                });
              }}
            >
              登録
            </ButtonSolid>
            <ButtonSolid
              h="26px"
              fontSize="14px"
              w="46px"
              onClick={() => {
                setModePrint("portrait");
                setParamsHistory({
                  patientId: item.patient.id,
                  nurseId: item.nurse.id,
                  id: item.id,
                });
                setIsHistoryPrinting(true);
              }}
              className="ml-2"
              isLoading={isHistoryPrinting && paramsHistory?.id === item.id}
            >
              出力
            </ButtonSolid>
          </div>
        ),
      },
    ];
  }, [clientIdsObj, nursingCareHitories, isHistoryPrinting]);

  const handleChangeCurrentDate = (date: Dayjs) => {
    setCurrentDate(date);
    setClientIdsObj({});
    navigate({
      pathname: window.location.pathname,
      search: createSearchParams({
        currentDate: date.format(DATE_FORMAT),
      }).toString(),
    });
  };

  const hanldeBack = () => {
    navigate(`/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}`);
  };

  useEffect(() => {
    const newDate = dayjs(searchParams.get(CURRENT_DATE_KEY)).isValid()
      ? dayjs(searchParams.get(CURRENT_DATE_KEY))
      : dayjs();
    setCurrentDate(newDate);
  }, [searchParams]);

  const idToken = getCookie("access_token");

  const handlePrintServiceProvisionRecord = async () => {
    const year_month = currentDate?.format(MONTH_FORMAT);
    if (!clientIdsObj) return;
    setPrinting(true);
    setPreventAction(true);

    const checkIdExisted: any = {};
    const client_ids = Object.values(clientIdsObj).reduce((acc, cur) => {
      if (checkIdExisted[cur.clientId]) return acc;
      acc.push(cur.clientId);
      checkIdExisted[cur.clientId] = true;
      return acc;
    }, [] as number[]);

    const param = {
      per: "10000",
    };

    const promiseDataPrint =
      apiFactory.adminNursingCareRecordApi.pdfServiceDelivery({
        year_month,
        client_ids,
      });
    const promiseServiceTypes = await nursingCareRecordApi.getServiceType(
      idToken,
      param,
      user?.role
    );
    Promise.all([promiseDataPrint, promiseServiceTypes])
      .then((result) => {
        const [dataPrint, dataServiceTypes] = result;
        const data = dataPrint.data as IItemService[];
        const serviceTypes =
          dataServiceTypes.serialized_service_types as IServiceType[];
        setServiceTypes(serviceTypes);
        setDataPrint(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (isReadyPrint) {
      setIsReadyPrint(false);
      setDataPrint([]);
      handlePrint();
    }
  }, [isReadyPrint]);

  useEffect(() => {
    const handleSetOpenPopupBtnsPdf = (e: MouseEvent) => {
      if(!(e.target as HTMLElement).closest(".ignone_popup_pdf")) {
        setIsOpenBtnsPdf(false)
      }
    }
    document.addEventListener("mousedown", handleSetOpenPopupBtnsPdf)
    return () => {
      document.removeEventListener("mousedown", handleSetOpenPopupBtnsPdf)
    }
  })

  const buttonPdfs = (
    <div className="ignone_popup_pdf">
      <ButtonSolid
        onClick={() => {
          if (preventAction) return;
          setModePrint("portrait");
          setParamsHistory({
            patientId: "",
            nurseId: "",
            id: "",
          });
          setIsHistoryPrinting(true);
        }}
        className="h-10 w-[244px] !rounded-sm border !font-medium mb-3"
        isLoading={isHistoryPrinting && !paramsHistory?.id}
        isDisabled={printing}
      >
        介護記録の一括出力
      </ButtonSolid>
      <br />
      <ButtonSolid
        onClick={() => {
          if (preventAction) return;
          setModePrint("portrait");
          setModalPDF({
            type: "notificationOfAmountReceived",
            open: true,
          });
        }}
        className="h-10 w-[244px] !rounded-sm border !font-medium mb-2"
        isDisabled={
          isEmpty(clientIdsObj) ||
          printing ||
          (isHistoryPrinting && !paramsHistory?.id)
        }
      >
        代理受領額通知書
      </ButtonSolid>
      <br />
      <ButtonSolid
        onClick={() => {
          if (preventAction) return;
          setModePrint("landscape");
          setModalPDF({
            type: "service",
            open: true,
          });
        }}
        className="h-10 w-[244px] !rounded-sm border !font-medium mb-2"
        isDisabled={
          isEmpty(clientIdsObj) ||
          printing ||
          (isHistoryPrinting && !paramsHistory?.id)
        }
      >
        サービス提供表
      </ButtonSolid>
      <br />
      <ButtonSolid
        onClick={() => {
          if (preventAction) return;
          handlePrintServiceProvisionRecord();
          setModePrint("portrait");
        }}
        className="h-10 w-[244px] !rounded-sm border !font-medium mb-3"
        isDisabled={
          isEmpty(clientIdsObj) || (isHistoryPrinting && !paramsHistory?.id)
        }
        isLoading={printing}
      >
        居宅介護サービス提供実績記録票
      </ButtonSolid>
    </div>
  );

  return (
    <div className="pb-[100px]">
      <style>
        {`
            @media print {
              @page {
                size: ${modePrint};
                padding:6mm
              }
              .break-inside {
                break-inside: avoid;
              }
            }
            `}
      </style>
      <div className="flex mb-[22px] mt-[10px]">
        <p className="font-bold mr-[10px]">介護記録・履歴　記入者</p>
        <p>{searchParams?.get("nursing_name") || ""}</p>
      </div>

      <div className="bg-white px-[10px] py-[20px]">
        <div className="mb-[20px] border-b flex justify-between">
          <div></div>
          <MonthChanger onChange={handleChangeCurrentDate} />
          <Popover
            placement="leftTop"
            trigger={"click"}
            title={""}
            content={buttonPdfs}
            open={isOpenBtnsPdf}
          >
            <ButtonSolid
              className="h-10 w-[87px] h-10 !rounded-sm border !font-medium ignone_popup_pdf"
              onClick={() => {setIsOpenBtnsPdf(!isOpenBtnsPdf)}}
            >
              PDF出力
            </ButtonSolid>
          </Popover>
        </div>

        <Table
          pagination={false}
          loading={isLoading || isFetchingNursingCareHistories}
          dataSource={nursingCareHitories}
          columns={columns}
          scroll={{
            x: 600,
          }}
        />
      </div>

      <div className="bg-white fixed bottom-0 right-0 w-[100vw] h-[80px] flex justify-end items-center pr-[20px]">
        <ButtonSolid onClick={hanldeBack}>一覧に戻る</ButtonSolid>
      </div>
      <ModalReceiptAmountPrintPDF
        currentDate={currentDate}
        clientIdsObj={clientIdsObj}
        open={
          !!modalPDF?.open && modalPDF.type === "notificationOfAmountReceived"
        }
        setModalPDF={setModalPDF}
        onCancel={() => {
          setModalPDF(null);
        }}
      />
      <ModalPrintServicePDF
        currentDate={currentDate}
        clientIdsObj={clientIdsObj}
        open={!!modalPDF?.open && modalPDF.type === "service"}
        setModalPDF={setModalPDF}
        onCancel={() => {
          setModalPDF(null);
        }}
      />

      {dataPrint.length > 0 && (
        <ServiceProvisionRecord
          dataPrint={dataPrint}
          setIsReadyPrint={setIsReadyPrint}
          currentDate={currentDate}
          serviceTypes={serviceTypes}
          ref={ref}
        />
      )}

      {paramsHistory && (
        <PrintPdfNursingCareRecord
          setParamsHistory={setParamsHistory}
          nurseId={id}
          setIsPrinting={setIsHistoryPrinting}
          currentDate={currentDate}
          patientId={paramsHistory.patientId}
        />
      )}
    </div>
  );
}

export default NursingCareRecordDetail;
