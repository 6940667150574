import { useEffect, useState } from "react";
import { Button, DatePicker, Form, ModalProps } from "antd";
import ModalStyled from "./styled";
import { Flex } from "@chakra-ui/react";
import { ButtonSolid } from "component/button";
import usePrint from "hook/usePrint";
import { useForm } from "antd/es/form/Form";

import NotificationReceiptAmount from "../PDFs/NotificationReceiptAmount";
import apiFactory from "api";
import { Dayjs } from "dayjs";
import { MONTH_FORMAT } from "constant/date";
import { IItemPaymentReceiptNotice } from "types/admin";
import { CloseOutlined } from "@ant-design/icons";

const formItems = {
  notificationOfAmountReceived: [,],
  serviceAvailabilityTable: [
    <Form.Item
      key="3 "
      className="w-full"
      rules={[{ required: true }]}
      name="CreatedDate"
      label="作成日"
    >
      <DatePicker className="h-10  w-full" placeholder="作成日" />
    </Form.Item>,
  ],
};

export type IModalReceiptAmountPrintPDFProps = {
  type?: keyof typeof formItems;
  currentDate?: Dayjs;
  clientIdsObj?: { [key: string]: { checked: boolean; clientId: number } };
  setModalPDF: React.Dispatch<
    React.SetStateAction<{
      type: "service" | "notificationOfAmountReceived";
      open: boolean;
    } | null>
  >;
} & ModalProps;

const ModalReceiptAmountPrintPDF = ({
  currentDate,
  clientIdsObj,
  open,
  setModalPDF,
  ...restProps
}: IModalReceiptAmountPrintPDFProps) => {
  const [form] = useForm();

  const [dataPrint, setDataPrint] = useState<
    IItemPaymentReceiptNotice[] | null
  >(null);
  const [isReadyPrint, setIsReadyPrint] = useState(false);
  const [printing, setPrinting] = useState(false);
  const { ref, handlePrint } = usePrint(true, {
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  const receipt_date = Form.useWatch("receipt_date", form);
  const issue_date = Form.useWatch("issue_date", form);

  const handleClose = () => {
    setModalPDF({
      type: "notificationOfAmountReceived",
      open: false,
    });
    form.resetFields();
  };

  const footer = (
    <Flex gap={4} className="mt-[46px]">
      <Button className="flex-1 h-12 !rounded-sm" onClick={handleClose}>
        キャンセル
      </Button>
      <ButtonSolid
        className="flex-1 !h-12 !rounded-sm border"
        onClick={form.submit}
        isLoading={printing}
        isDisabled={!issue_date || !receipt_date}
      >
        確認
      </ButtonSolid>
    </Flex>
  );

  const handleSubmit = async () => {
    try {
      const year_month = currentDate?.format(MONTH_FORMAT);
      if (!clientIdsObj) return;
      setPrinting(true);

      const checkIdExisted: any = {};
      const client_ids = Object.values(clientIdsObj).reduce((acc, cur) => {
        if (checkIdExisted[cur.clientId]) return acc;
        acc.push(cur.clientId);
        checkIdExisted[cur.clientId] = true;
        return acc;
      }, [] as number[]);

      const receipt_date = form.getFieldValue("receipt_date");
      const issue_date = form.getFieldValue("issue_date");
      const result =
        await apiFactory.adminNursingCareRecordApi.pdfPaymentReceiptNotice({
          year_month,
          issue_date,
          receipt_date,
          client_ids,
        });

      const data = result.data as IItemPaymentReceiptNotice[];
      setDataPrint(data);
    } catch (error) {
      setPrinting(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    if (isReadyPrint) {
      handlePrint();
      setDataPrint(null);
      setIsReadyPrint(false);
    }
  }, [isReadyPrint]);

  return (
    <ModalStyled
      open={open}
      footer={footer}
      {...restProps}
      onCancel={() => {}}
      closeIcon={
        <div onClick={handleClose}>
          <CloseOutlined />
        </div>
      }
    >
      <h1 className="font-bold text-xl mb-5">ダウンロード前の確認</h1>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          key="1"
          className="w-full"
          rules={[{ required: true }]}
          name="issue_date"
          label="発行年月日"
        >
          <DatePicker className="h-10  w-full" placeholder="発行年月日" />
        </Form.Item>
        <Form.Item
          key="2"
          className="w-full"
          rules={[{ required: true }]}
          name="receipt_date"
          label="受領日付"
        >
          <DatePicker className="h-10  w-full" placeholder="受領日付" />
        </Form.Item>
      </Form>
      {dataPrint && (
        <NotificationReceiptAmount
          ref={ref}
          dataPrint={dataPrint}
          setIsReadyPrint={setIsReadyPrint}
          receiptDate={form.getFieldValue("receipt_date")}
          issueDate={form.getFieldValue("issue_date")}
          currentDate={currentDate}
        />
      )}
    </ModalStyled>
  );
};

export default ModalReceiptAmountPrintPDF;
