import { divide } from "lodash";

export const ROLE = {
  ADMIN: "admin",
  STAFF: "staff",
  CLIENT: "client",
  HS_ADMIN: "admin_home_system",
  HS_STAFF: "staff_home_system",
};
export const PREFECTURE = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

export const EVENT_STATUS = {
  not_assign: {
    className: "info",
    bg: "rgba(115, 103, 240, 0.12)",
    color: "#7367F0",
  },
  not_enough_assign: {
    className: "error",
    color: "#F64747",
    bg: "rgba(247, 71, 71, 0.12)",
  },
  assigned: {
    className: "success",
    bg: "rgba(40, 199, 111, 0.12)",
    color: "#28C76F",
  },
};

export const CARE_PLAN_TYPE_OPTIONS = [
  {
    value: "NursingCarePlan",
    content: "介護",
  },
  {
    value: "DisabilityCarePlan",
    content: "障害",
  },
];

export const SHIFT_TYPES = {
  day_off: {
    value: "day_off",
    color: "#d82121",
    label: "休",
    border: '#be1e1d'
  },
  early_shift: {
    value: "early_shift",
    color: "#9747ff",
    label: "早",
    border:"#853fe0"
  },
  late_shift: {
    value: "late_shift",
    color: "#E36DC9",
    label: "遅",
    border: "#c761b1"
  },
  night_shift: {
    value: "night_shift",
    color: "#00870D",
    label: "夜",
    border: "#00780b"
  },
  afternoon_shift: {
    value: "afternoon_shift",
    color: "#EA7E2C",
    label: "明",
    border: "#ce7027"
  },
  day_shift_1: {
    value: "day_shift_1",
    color: "#B37FEB",
    label: "日1",
    border: "#9d71ce"
  },
  day_shift_2: {
    value: "day_shift_2",
    color: "#5D5FEF",
    label: "日2",
    border: "#5254d2"
  },
  day_shift_3: {
    value: "day_shift_3",
    color: "#000E96",
    label: "日3",
    border: "#000d84"
  },
  required: {
    value: "required",
    color: "#EB2F96",
    label: "要",
    border: "#ce2a84"
  },
};

export const SERVICE_CONTENTS = {
  inHomeCare: "居宅介護",
  serviceHomeVisitNursingCare: "重度訪問介護",
};

export const EMPLOYMENT_TYPE_OPTIONS = [
  {
    label: "社員",
    value: "full_time",
  },
  {
    label: "パート1",
    value: "part_time_1",
  },
  {
    label: "パート2",
    value: "part_time_2",
  },
  {
    label: "未定",
    value: "undecided",
  },
];