import React, {
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Actions } from "../enum";
import { arrayMove } from "@dnd-kit/sortable";

export interface TableContextProps {
  dispatch?: React.Dispatch<{
    type: Actions;
    payload?: any;
  }>;
  isDraging: boolean;
  listSchedulesDeleted?: string[];
}

export const context = React.createContext<TableContextProps>({
  isDraging: false,
});
const Provider = context.Provider;

function reducer(
  state: any,
  action: {
    type: Actions;
    payload?: any;
  }
) {
  if (action.type === Actions.ToggleDranging) {
    return {
      ...state,
      isDraging: action.payload,
    };
  } else if (action.type === Actions.InfoDranging) {
    return {
      ...state,
      idDraging: action.payload.idDraging,
      isDraging: action.payload.isDraging,
    };
  } else if (action.type === Actions.SetListDeleted) {
    return {
      ...state,
      listSchedulesDeleted: action.payload,
    };
  }
  return state;
}

interface IProps {
  children: ReactNode;
  refTable: React.MutableRefObject<HTMLDivElement | null>;
  dataSource: readonly any[] | undefined;
  onDragEnd?: (sortedArray: string[]) => void;
  listSchedulesDeleted?: string[];
  refSorted?: MutableRefObject<string[]>;
}
const TableContext = ({
  children,
  refTable,
  dataSource,
  onDragEnd,
  listSchedulesDeleted,
  refSorted,
}: IProps) => {
  const [state, dispatch] = useReducer(reducer, { isDraging: false });

  const origin = useMemo(() => {
    return (
      dataSource
        ?.filter((item) => !listSchedulesDeleted?.includes(item.key))
        ?.reduce((acc, cur, index) => {
          acc[cur.key] = index;
          return acc;
        }, {}) || {}
    );
  }, [dataSource, listSchedulesDeleted]);

  const [items, setItems] = useState<string[]>([]);
  if (refSorted?.current) refSorted.current = items;
  useEffect(() => {
    const atSlice = items.length;
    if (atSlice >= 0) {
      const newItems =
        dataSource
          ?.filter((item) => !listSchedulesDeleted?.includes(item.key))
          ?.slice(atSlice)
          ?.map((x) => x.key.toString()) || [];
      setItems([...items, ...newItems]);
    }

    if (items.length && (dataSource || []).length < items.length) {
      const newItems = dataSource?.map((x) => x.key.toString()) || [];
      setItems(newItems);
    }
  }, [dataSource]);

  useEffect(() => {
    const newItems = items.filter((id) => !listSchedulesDeleted?.includes(id));
    newItems.forEach((id, index) => {
      const rowEffect = refTable.current?.querySelector(
        `[data-row-key='${id}']`
      ) as HTMLElement;
      const originIndex = origin[id as keyof typeof origin];
      const y = (index - originIndex) * rowEffect.offsetHeight;
      rowEffect.style.transform = `translate(0px, ${y}px)`;
    });
    setItems(newItems);
    onDragEnd && onDragEnd(newItems);
    dispatch({
      type: Actions.SetListDeleted,
      payload: listSchedulesDeleted,
    });
  }, [listSchedulesDeleted]);

  useEffect(() => {
    let yStart = 0;
    const idDraging = state.idDraging;
    const row = refTable.current?.querySelector(
      `[data-row-key='${idDraging}']`
    ) as HTMLElement;
    if (!row) return;

    const activeIndex = items.findIndex((id) => id === idDraging);
    let overIndex = activeIndex;
    const height = row.offsetHeight || 0;

    const handleDrag = (e: MouseEvent) => {
      const y = e.y - yStart;
      if (y === 0) return;
      const overCountRow =
        y > 0
          ? Math.ceil((y - height / 2) / height)
          : Math.ceil((y + height / 2) / height) - 1;
      const originActiveIndex = origin[idDraging as keyof typeof origin];
      const yRow = y + (activeIndex - originActiveIndex) * height;
      overIndex = activeIndex + overCountRow;
      if (overIndex < 0) overIndex = 0;
      else if (overIndex > items.length - 1) overIndex = items.length - 1;
      const newItems = arrayMove(items, activeIndex, overIndex);
      newItems.forEach((id, index) => {
        const rowEffect = refTable.current?.querySelector(
          `[data-row-key='${id}']`
        ) as HTMLElement;
        if (!rowEffect || row === rowEffect) return;
        const originIndex = origin[id as keyof typeof origin];
        const y = (index - originIndex) * height;
        rowEffect.style.transform = `translate(0px, ${y}px)`;
      });
      row.style.transform = `translate(0px, ${yRow}px)`;
    };

    const handleMouseUp = (e: MouseEvent) => {
      dispatch({
        type: Actions.ToggleDranging,
        payload: false,
      });

      const newItems = arrayMove(items, activeIndex, overIndex);
      onDragEnd && onDragEnd(newItems);
      newItems.forEach((id, index) => {
        const rowEffect = refTable.current?.querySelector(
          `[data-row-key='${id}']`
        ) as HTMLElement;
        if (!rowEffect) return;
        const originIndex = origin[id as keyof typeof origin];
        const y = (index - originIndex) * height;
        rowEffect.style.transform = `translate(0px, ${y}px)`;
      });
      if (activeIndex === overIndex) return;
      setItems(newItems);
    };

    const handleMouseDown = (e: MouseEvent) => {
      yStart = e.y;
    };
    window.addEventListener("mouseup", handleMouseUp);

    if (state.isDraging) {
      window.addEventListener("mousemove", handleDrag);
      window.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [state.isDraging, items]);

  return (
    <Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </Provider>
  );
};

export default TableContext;
