import { Button } from "antd";
import { useContext } from "react";
import { HolderOutlined } from "@ant-design/icons";
import { context } from "./TableContext";
import { Actions } from "../enum";
interface Iprops {
  isDisabled?: boolean;
  id: string;
}

const DragHandle = ({ isDisabled, id }: Iprops) => {
  const { dispatch } = useContext(context);
  return (
    <div>
      {isDisabled ? (
        <Button
          type="text"
          size="small"
          icon={<HolderOutlined />}
          style={{ cursor: "not-allowed" }}
        />
      ) : (
        <Button
          type="text"
          size="small"
          icon={<HolderOutlined />}
          style={{ cursor: "move " }}
          onMouseDown={() => {
            dispatch &&
              dispatch({
                type: Actions.InfoDranging,
                payload: {
                  isDraging: true,
                  idDraging: id,
                },
              });
          }}
        />
      )}
    </div>
  );
};

export default DragHandle;
