import type { DragEndEvent, DragMoveEvent } from "@dnd-kit/core";
import { Table, TableProps } from "antd";
import Row from "./compoment/Row";
import DragHandle from "./compoment/DragHandle";
import { forwardRef, MutableRefObject, useMemo, useRef } from "react";
import TableContext from "./compoment/TableContext";
import { ColumnsType } from "antd/es/table";

interface IPropsTableDragDrop extends TableProps<any> {
  columns: any;
  isDisabled?: boolean;
  onDragEnd?: (sortedArray: string[]) => void;
  onDragMove?: (e: DragMoveEvent) => void;
  listSchedulesDeleted?: string[];
  refSorted?: MutableRefObject<string[]>;
  isSort?: boolean;
}

const TableDragDrop = (props: IPropsTableDragDrop, ref?: any) => {
  const {
    columns,
    dataSource,
    onDragEnd,
    onDragMove,
    isDisabled,
    listSchedulesDeleted,
    refSorted,
    isSort,
    ...restProps
  } = props;
  const dataSourceKeyString = useMemo(() => {
    return (
      dataSource?.map((item: any) => {
        return { ...item, key: item?.key?.toString() };
      }) || []
    );
  }, [dataSource]);
  const refTable = useRef<HTMLDivElement | null>(null);
  const columnsSoft: ColumnsType<any> = [
    {
      key: "sort",
      align: "center",
      width: 50,
      render: (record) => {
        return (
          <div>
            <DragHandle isDisabled={isDisabled} id={record.key} />
          </div>
        );
      },
    },
    ...columns,
  ];

  return (
    <TableContext
      refTable={refTable}
      dataSource={dataSourceKeyString}
      onDragEnd={onDragEnd}
      listSchedulesDeleted={listSchedulesDeleted}
      refSorted={refSorted}
    >
      <Table
        ref={refTable}
        rowKey="key"
        components={{ body: { row: Row } }}
        scroll={{ x: "max-content" }}
        columns={isSort ? columnsSoft : columns}
        dataSource={dataSourceKeyString}
        pagination={false}
        {...restProps}
      />
    </TableContext>
  );
};

export default forwardRef(TableDragDrop); //Deprecated in React v19. Ref at https://react.dev/blog/2024/12/05/react-19#ref-as-a-prop
